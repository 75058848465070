<template>
  <div class="about">
    <div class="about_banner"></div>
    <h2 class="h2 brief">企业简介</h2>
    <div class="about_context">
      <p>
        湖南优享云通信技术有限公司成立于2018年，立志成为国内网络安全认证产品及解决方案提供商。 公司成立以来，一直不忘初心，埋头苦干，潜心研发，
        从单一的全场景身份认证与访问控制产品提供 商逐步进化成为各行业客户在移动化及物联网转型中提供安全及运维管理综合产品提供商，截至目前，
        公司共有软件著作权 10项、注册商标 1 项，已通过 ISO9001 质量管理体系认证、国家信息安全产品 认证。
      </p>
      <p>
        公司总部位于湖南长沙，在长沙、北京均设立研发中心。同时在北京、深圳、广州、武汉设有分支 机构，
        以提供更优质的本地服务。成立至今，已与国内各行业客户建立合作伙伴关系， 如阿里巴巴、
        金山云、万豪酒店、中铁五局等。是教育、政府、医疗、金融、能源、广电、大型商业综合体等行业值 得信赖的供应商。
      </p>
      <p>
        雄关漫道真如铁，而今迈步从头越，湖南优享云通信技术有限公司期待为广大客户提供更为优质的 产品与服务，也期待与您一道创造更加美好的明天！
      </p>
    </div>
    <h2 class="h2 us">联系我们</h2>
    <div class="about_map">
      <div class="about_right">
        <h3>湖南优享云通信技术有限公司</h3>
        <div class="arow">
          <span class="about_smalltitle">联系人:</span>
          <span class="about_smalltext">李先生</span>
        </div>
        <div class="arow">
          <span class="about_smalltitle">联系电话:</span>
          <span class="about_smalltext">189-3245-1909</span>
        </div>
        <div class="arow">
          <span class="about_smalltitle">地址:</span>
          <span class="about_smalltext">长沙市天心区天经国际智能产业园1栋1201室</span>
        </div>
        <h4>北京分公司</h4>
        <div class="arow">
          <span class="about_smalltitle">联系人:</span>
          <span class="about_smalltext">彭先生(150-1036-6164)</span>
        </div>
        <div class="arow">
          <span class="about_smalltitle">联系电话:</span>
          <span class="about_smalltext">181-5388-6184</span>
        </div>
        <div class="arow">
          <span class="about_smalltitle">地址:</span>
          <span class="about_smalltext">北京市通州区金融街园中园6号院53号楼</span>
        </div>
      </div>
      <div class="about_left">
        <baidu-map :center="center" :zoom="zoom" @ready="handler" @click="getClickInfo" :scroll-wheel-zoom="true" class="about_left_map">
          <bm-label
            content="湖南优享云通信技术有限公司"
            :position="{ lng: 112.993716, lat: 28.072274 }"
            :labelStyle="{ color: '#333', fontSize: '14px', border: 'none' }"
            title="Hover me"
          />
        </baidu-map>
      </div>
    </div>
    <FootMain :isBanner="isBanner"></FootMain>
  </div>
</template>

<script>
import FootMain from '@/components/m/foot_main/index.vue'
export default {
  components: { FootMain },
  data() {
    return {
      center: { lng: 112.993716, lat: 28.072274 }, //lng代表纬度 lat代表经度
      zoom: 19,
      isBanner: false
    }
  },
  methods: {
    handler({ BMap, map }) {
      var point = new BMap.Point(112.993716, 28.072274)
      map.centerAndZoom(point, 18)
      var marker = new BMap.Marker(point) // 创建标注
      map.addOverlay(marker) // 将标注添加到地图中
      var circle = new BMap.Circle(point, 1, {
        strokeColor: 'Red',
        strokeWeight: 1,
        strokeOpacity: 1,
        Color: 'Red',
        fillColor: '#f03'
      })
      map.addOverlay(circle)
    },
    getClickInfo(e) {
      // console.log(e.point.lng);
      // console.log(e.point.lat);
      this.center.lng = e.point.lng
      this.center.lat = e.point.lat
    },
    labelStyle() {
      return '{color: "#333", fontSize : "14px";border:"none" }'
    }
  }
}
</script>

<style scoped lang="scss">
.about_banner {
  height: 12.236842rem;
  width: 100%;
  background-image: url('~@/assets/img/pc/about/about_banner.jpg');
  background-size: auto 100%;
}
.brief {
  margin-top: 1.315789rem;
  margin-bottom: 2.368421rem;
}
.about_context {
  width: 43.815789rem;
  margin: 0 auto;
  p {
    padding: 0 0.851063rem;
    text-indent: 2em;
    font-size: 0.947368rem;
    color: #333;
    line-height: 1.8em;
    margin: 0;
  }
}
.us {
  margin: 2.368421rem 0;
}
.about_map {
  width: 100%;
  .about_left {
    width: 100%;
    height: 21.276595rem;
    .about_left_map {
      height: 100%;
    }
  }
  .about_right {
    text-align: center;
    height: 100%;
    padding-left: 1.842105rem;
    h3 {
      font-size: 1.2rem;
    }
    .arow {
      margin-bottom: 1.052631rem;
    }
    .about_smalltitle {
      font-size: 1rem;
      margin-right: 0.526315rem;
    }
    .about_smalltext {
      font-size: 0.9rem;
      color: #2a2a2a;
    }
    h4 {
      font-size: 1.2rem;
      margin: 0 0 1.052631rem;
    }
  }
}
</style>
<style scoped lang="scss">
.h2 {
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  position: relative;
  &::after {
    display: block;
    content: '';
    height: 0.368421rem;
    width: 3.815789rem;
    background-color: #06f410;
    border-radius: 0.18421rem;
    position: absolute;
    bottom: -0.657894rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
